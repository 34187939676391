import React, { useState, useEffect } from "react"

import Loadable from "react-loadable";

import flatfileService from "../utils/api/v1/flatfileService";

const LoadingComponent = (props: any) => {
  return <div>Loading...</div>;
};

const ItemLoadableComponent = Loadable({
  loader: () => import("../components/massiveUpload/ItemUpload"),
  loading: LoadingComponent,
  render(loaded, props) {
    const Component = loaded.default;
    return <Component {...props} />;
  }
});

const LabMeasureLoadableComponent = Loadable({
  loader: () => import("../components/massiveUpload/LabMeasureUpload"),
  loading: LoadingComponent,
  render(loaded, props) {
    const Component = loaded.default;
    return <Component {...props} />;
  }
});

const LabPanelLoadableComponent = Loadable({
  loader: () => import("../components/massiveUpload/LabPanelUpload"),
  loading: LoadingComponent,
  render(loaded, props) {
    const Component = loaded.default;
    return <Component {...props} />;
  }
});

const LabItemPricesUpdateComponent = Loadable({
  loader: () => import("../components/massiveUpload/LabItemPricesUpdate"),
  loading: LoadingComponent,
  render(loaded, props) {
    const Component = loaded.default;
    return <Component {...props} />;
  }
});

function MassiveUpload() {
  const [error, setError] = useState<string | null>(null);
  const [flatfileTokens, setFlatfileTokens] = useState<Record<string, string>>({
    measures: "",
    panels: "",
    "medicalservices": "",
    "lab-measures": "",
    "lab-panels": "",
  });

  const fetchToken = async () => {
    try {
      const req = await flatfileService.getMassiveUploadTokens();
      setFlatfileTokens(req.data);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  return (
    <>
      <div className="flex items-center">
        <h1 className="mb-3">Carga Masiva</h1>
        <a
          target="_blank"
          href="https://examedi.notion.site/Carga-Masiva-Betty-eff428adb7dc4c30a36e1e57eb90d730"
          className="pl-6 no-underline"
        >
          ¿Cómo funciona?
        </a>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <ItemLoadableComponent
          flatfileToken={flatfileTokens["measures"]}
          type="measures"
        />
        <ItemLoadableComponent
          flatfileToken={flatfileTokens["panels"]}
          type="panels"
        />
        <ItemLoadableComponent
          flatfileToken={flatfileTokens["medicalservices"]}
          type="medicalservices"
        />
        <LabMeasureLoadableComponent flatfileToken={flatfileTokens["lab-measures"]} />
        <LabPanelLoadableComponent flatfileToken={flatfileTokens["lab-panels"]} />
        <LabItemPricesUpdateComponent flatfileToken={flatfileTokens["price-update"]}/>
      </div>
    </>
  )
}

export default MassiveUpload
