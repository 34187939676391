import api from "../api";
import { AxiosResponse } from "axios";

const appointmentsBaseUrl = 'appointments';

class FlatfileService {
  getFlatfileToken = (): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/flatfile_token/`);
  }

  getFlatfileTokenMx = (): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/flatfile_token_mx/`);
  }

  getMassiveUploadTokens = (): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/massive-upload-tokens/`);
  }

  downloadFlatfileTemplate = (): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/flatfile_template/`, { responseType: 'blob' });
  }
}

const flatfileService = new FlatfileService();

export default flatfileService;
